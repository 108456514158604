<template>
  <div>

    <b-modal
      v-model="modalShow"
      header-bg-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
      centered
    >
      <template
        #modal-header="{ close }"
        class="text-center"
      >
        <h4 class="text-white">
          Iniciar atención
        </h4>
        <b-button
          size="sm"
          @click="close()"
        >
          X
        </b-button>

      </template>
      <div class="text-center my-4">
        <label style="font-size:14px;">Seleccione la fecha de atención:</label>
        <b-form-group>
          <b-form-input
            v-model="diaElegir"
            type="date"
            required
            @input="setFechaRegistro"
          />
        </b-form-group>
        <b-button
          v-if="!spinnerShow"
          variant="danger"
          @click="onIniciarAsistencia"
        >
          Iniciar
        </b-button>
        <b-spinner
          v-else
          style="width: 5rem; height: 5rem;"
          variant="primary"
          label="Text Centered"
        />
      </div>
    </b-modal>

    <div class="m-0 p-0">
      <b-form
        class="m-0 p-0"
        @submit.prevent="onSubmit"
      >

        <b-row class="mb-0 p-0">
          <b-col
            md="12"
            class="m-0 px-1"
          >
            <b-card
              class="mb-1 rounded"
              :class="dia !== null ? 'border-success' : 'border-danger'"
              header-tag="header"
              :header-bg-variant="`light-${dia !== null ? 'primary' : 'danger'}`"
              header-class="m-0 p-1"
              body-class="pb-0"
            >
              <template
                #header
              >
                <div class="">
                  <strong><em>FECHA:</em></strong>
                  <span
                    class="ml-1 font-weight-bold"
                    style="font-size:20px;"
                  >{{ dia !== null ? new Intl.DateTimeFormat('es-PE',{month: 'numeric', day:'numeric', year: 'numeric'}).format(new Date(`${dia}T00:00:00`)) : '-- / -- / --' }}</span>
                </div>
                <div class="d-flex flex-wrap align-items-center ">
                  <strong><em>COMIDA:</em></strong>
                  <span class="ml-1">
                    <b-form-select
                      v-model="comida"
                      size="sm"
                      style="width:200px"
                      class="text-uppercase"
                      :options="options"
                      required
                      @input="actualizarTablasAsistencias"
                    />
                  </span>
                </div>
                <div>
                  <b-button
                    size="sm"
                    variant="dark"
                    @click="actualizarRegistroAsistencia"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                    />
                    {{ dia !== null ? 'Cambiar fecha de atención' : 'Iniciar atención' }}
                  </b-button>
                  <b-button
                    v-if="dia !== null ? true : false"
                    size="sm"
                    variant="danger"
                    style="margin-left:10px;"
                    @click="finalizarAtencion"
                  >

                    Finalizar
                  </b-button>
                </div>
              </template>

              <b-row>
                <b-col
                  :class="dia !== null ? '' : 'py-5'"
                  :md="dia !== null ? '6' : '12'"
                >
                  <div
                    id="reloj"
                    style="font-size: 40px"
                    :class="dia !== null ? 'text-success' : 'text-danger'"
                    class="mt-1"
                  >
                    <span><feather-icon
                      icon="ClockIcon"
                      size="35"
                      :class="dia !== null ? 'text-success' : 'text-danger'"
                    /></span>
                    {{ momentoActual }}
                  </div>
                  <div>
                    <h4 class="text-danger text-center pt-1">
                      <span
                        v-if="dia !== null"
                        class="text-success"
                      >
                        INICIADO
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      >
                        NO INICIADO
                      </span>
                    </h4>

                  </div>
                  <div
                    v-show="dia !== null ? true : false"
                    class="px-3"
                  >
                    <b-input-group class="border-primary rounded">
                      <b-form-input
                        v-model="numero_documento"
                        size="lg"
                        placeholder="Ingresar DNI o CE"
                        required
                        type="number"
                        class="bg-light-primary"
                        style="color:#0B6121; font-size: 30px;"
                        :disabled="dia !== null ? false :true "
                      />
                      <b-input-group-append>
                        <b-button
                          type="submit"
                          variant="primary"
                          block
                          :disabled="dia !== null ? false :true "
                        >
                          Registrar asistencia
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </b-col>
                <b-col
                  v-if="dia !== null ? true : false"
                  md="6"
                  class="flex align-items-center"
                >
                  <b-card
                    :header="dataResponse.mensaje"
                    :header-bg-variant="cardColor"
                    header-text-variant="light"
                    header-class="text-uppercase font-weight-bold m-0 p-1"
                    class="mt-1"
                    :class="`border-${cardColor}`"
                    body-class="p-0"
                  >
                    <b-card-body style="padding-top: 5px;">
                      <!-- DNI -->

                      <b-row>
                        <b-col cols="5">
                          <strong> Estudiante:</strong>
                        </b-col>
                        <b-col cols="7">
                          {{ dataResponse.asistencia.persona.nombre_completo }}
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="5">
                          <strong> DNI/C.E:</strong>
                        </b-col>
                        <b-col cols="7">
                          {{ dataResponse.asistencia.persona.numero_documento }}
                        </b-col>
                      </b-row>
                      <!-- Fecha y hora de registro -->
                      <b-row>
                        <b-col cols="5">
                          <strong> Fecha de registro:</strong>
                        </b-col>
                        <b-col cols="7">
                          {{ dataResponse.fecha_registro }}
                        </b-col>
                      </b-row>
                      <!-- Fecha y hora de registro -->
                      <b-row>
                        <b-col cols="5">
                          <strong> Comida:</strong>
                        </b-col>
                        <b-col
                          cols="7"
                          class="text-uppercase"
                        >
                          {{ dataResponse.comida }}
                        </b-col>
                      </b-row>

                    </b-card-body>

                  </b-card>
                </b-col>

              </b-row>

            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </div>

    <b-card
      v-show="dia !== null ? true : false"
      class="mt-0 p-0"
    >
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon
              size="15"
              icon="SmileIcon"
              class="text-primary"
            />
            <span>Asistidos </span><strong class="pl-1"> ( {{ totalAsistidos }} )</strong>
          </template>
          <tabla-asistidos
            :comida="comida"
            :update-tablas="updateTablas"
            :dia-atencion="dia"
            @updateTotalAsistidos="(updateTotalAsistidos) => totalAsistidos = updateTotalAsistidos "
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              size="15"
              icon="FrownIcon"
              class="text-danger"
            />
            <span>Becarios sin asistencia </span> <strong class="pl-1"> ( {{ totalNoAsistidos }} )</strong>
          </template>
          <tabla-no-asistidos
            :comida="comida"
            :update-tablas="updateTablas"
            :dia-atencion="dia"
            @updateTotalNoAsistidos="(updateTotalNoAsistidos) => totalNoAsistidos = updateTotalNoAsistidos "
          />
        </b-tab>

      </b-tabs>

    </b-card>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BButton, BCard, BSpinner, BRow, BCol, BFormSelect, BCardBody,
  BInputGroup, BTabs, BTab,
  BInputGroupAppend,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

import asistenciaBecariosStoreModule from './asistenciaBecariosStoreModule'
import TablaNoAsistidos from './TablaNoAsistidos.vue'
import TablaAsistidos from './TablaAsistidos.vue'

export default {
  components: {
    BTabs,
    BTab,
    // BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    TablaNoAsistidos,
    TablaAsistidos,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      updateTablas: false,
      totalAsistidos: 0,
      totalNoAsistidos: 0,
      fecha_registro: '2021-12-06',
      momentoActual: null,
      diaElegir: moment().format('YYYY-MM-DD'),
      dia: null,
      cardColor: 'light',
      modalShow: false,
      spinnerShow: false,
      options: ['desayuno', 'almuerzo', 'cena', 'otros'],
      selected: null,
      numero_documento: null,
      comida: null,
      dataResponse: {
        mensaje: '.',
        fecha_registro: null,
        comida: null,
        usuario: {
          name: '',
        },
        asistencia: {
          persona: {
            nombre_completo: null,
          },
        },
      },
      dataResponseDefault: {
        mensaje: null,
        fecha_registro: null,
        comida: null,
        usuario: {
          name: '',
        },
        asistencia: {
          persona: {
            nombre_completo: null,
          },
        },
      },

    }
  },
  created() {
    this.horaActual()
    setInterval(this.horaActual, 1000)
    if (localStorage.getItem('fechaIniciadaParaAsistencia')) {
      this.dia = localStorage.getItem('fechaIniciadaParaAsistencia')
      store.dispatch('asistencia/crearRegistroAsistencia', this.dia)
    }
  },
  mounted() {
    const horaActualHoy = new Date() // Actualizar fecha.
    const horaActual = horaActualHoy.getHours()
    if (horaActual >= 7 && horaActual <= 10) {
      this.comida = 'desayuno'
    } else
    if (horaActual >= 11 && horaActual <= 16) {
      this.comida = 'almuerzo'
    } else
    if (horaActual >= 17 && horaActual <= 20) {
      this.comida = 'cena'
    } else {
      this.comida = 'otros'
    }
    this.fecha_registro = this.dia
  },
  methods: {
    setFechaRegistro() {
      this.fecha_registro = this.diaElegir
    },
    actualizarRegistroAsistencia() {
      this.modalShow = true
    },
    horaActual() {
      setTimeout(() => {
        const fecha = new Date() // Actualizar fecha.
        let hora = fecha.getHours() // hora actual
        let minuto = fecha.getMinutes() // minuto actual
        let segundo = fecha.getSeconds() // segundo actual
        if (hora < 10) { // dos cifras para la hora
          hora = `0${hora}`
        }
        if (minuto < 10) { // dos cifras para el minuto
          minuto = `0${minuto}`
        }
        if (segundo < 10) { // dos cifras para el segundo
          segundo = `0${segundo}`
        }
        // devolver los datos:
        this.momentoActual = `${hora}:${minuto}:${segundo}`
        return this.momentoActual
      }, 1000)
    },

    actualizarTablasAsistencias() {
      this.updateTablas = !this.updateTablas
    },
    success(message) {
      this.$swal({
        title: '!Éxito!',
        text: `${message}`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(() => {
          this.modalShow = false
        })
    },
    onIniciarAsistencia() {
      this.fecha_registro = this.diaElegir
      this.spinnerShow = true
      store.dispatch('asistencia/crearRegistroAsistencia', this.diaElegir)
        .then(response => {
          this.spinnerShow = false
          this.dia = this.diaElegir
          this.diaElegir = null

          this.success(response.data)

          localStorage.setItem('fechaIniciadaParaAsistencia', this.dia)
          this.actualizarTablasAsistencias()
        }).catch(error => {
          this.spinnerShow = false
          if (error.response.data.code === 404) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.error,
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error al iniciar registro de atención',
                icon: 'CheckCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    onSubmit() {
      if (this.dia === '' || this.dia === null) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'No existe fecha seleccionada. ',
            icon: 'CheckCircleIcon',
            variant: 'warning',
          },
        })
        return
      }

      const formData = {
        numero_documento: this.numero_documento,
        comida: this.comida,
        fecha_registro: `${this.fecha_registro} ${this.momentoActual}`,
        // fecha_registro: moment().format(),
      }

      store.dispatch('asistencia/addBecarioAsistencias', formData)
        .then(response => {
          this.cardColor = 'success'
          this.dataResponse = response.data.data
          this.dataResponse.comida = response.data.data.comida
          this.actualizarTablasAsistencias()
        })
        .catch(error => {
          try {
            if (error.response.data.code === 422) {
              this.cardColor = 'warning'
              this.dataResponse = error.response.data.error.data
              this.dataResponse.comida = error.response.data.error.data.comida
            }
            if (error.response.data.code === 404) {
              this.cardColor = 'danger'
              this.dataResponse = Object.assign(this.dataResponseDefault, {})
              this.dataResponse.mensaje = error.response.data.error
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error al registrar',
                text: 'Recargar la página.',
                icon: 'CheckCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    finalizarAtencion() {
      this.dia = null
      localStorage.removeItem('fechaIniciadaParaAsistencia')
      this.fecha_registro = null
      this.success('¡Atención finalizada!')
    },

  },
  setup() {
    const ASISTENCIA_APP_STORE_MODULE_NAME = 'asistencia'

    if (!store.hasModule(ASISTENCIA_APP_STORE_MODULE_NAME)) store.registerModule(ASISTENCIA_APP_STORE_MODULE_NAME, asistenciaBecariosStoreModule)

    onUnmounted(() => {
      if (store.hasModule(ASISTENCIA_APP_STORE_MODULE_NAME)) store.unregisterModule(ASISTENCIA_APP_STORE_MODULE_NAME)
    })

    return {

    }
  },
}
</script>

<style lang="scss" scoped>
#reloj {
  // background: #4B0B0C;
  font-size: 1.2em;
  font-weight: bold;
  // color: #E70012;
  font-style: italic;
  padding: 2px;
  // border: #210007 5px solid;
  // border-radius: 10px;
  text-align: center;
}
::placeholder {
  color: #0B6121;
  font-size: 18px;
}
</style>
