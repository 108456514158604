import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBecarioAsistencias(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/comedor', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBecarioAsistencias(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/beca/comedor/registrar-asistencia', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crearRegistroAsistencia(ctx, dia) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/comedor/crear-registro-asistencia', { params: { dia } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
