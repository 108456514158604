<template>
  <div>
    <b-table
      id="tblAsistidos"
      ref="refTablaAsistidos"
      class="position-relative"
      show-empty
      head-variant="light"
      hover
      small
      responsive
      :busy.sync="isBusy"
      :items="myProvider"
      :no-provider-filtering="true"
      :fields="fields"
      :current-page="currentPage"
      :empty-text="'No hay registros para mostrar'"
      :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
          <strong>Cargando...</strong>
        </div>
      </template>

      <template #cell(index)="row">

        {{ from +row.index }}

      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon

                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import {
  BTable, BSpinner, BPagination, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {

    BTable,
    BSpinner,
    BPagination,
    BRow,
    BCol,

  },
  directives: {
    Ripple,
  },
  props: {
    diaAtencion: {
      type: String,
      default: '',
    },
    comida: {
      type: String,
      default: '',
    },
    updateTablas: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {

      isBusy: false,
      mostrar: false,
      items: [],
      fields: [
        { key: 'index', label: 'N°', sortable: true },
        { key: 'persona.numero_documento', label: 'DNI', sortable: true },

        { key: 'persona.nombre_completo', label: 'comensal', sortable: true },
        {
          key: 'comida', label: 'comida', sortable: true, class: 'text-uppercase',
        },
        { key: 'fecha_registro', label: 'fecha_registro', sortable: true },
      ],
      from: 1,
      to: 1,
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 15, 50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],

    }
  },
  watch: {
    totalRows() {
      this.$emit('updateTotalAsistidos', this.totalRows)
    },
    updateTablas() {
      this.actualizarTablaAsistidos()
    },

  },
  created() {
    // this.$parent.$on('actualizarTablaAsistidos', this.actualizarTablaAsistidos)
  },
  methods: {
    actualizarTablaAsistidos() {
      this.filter = null
      this.$refs.refTablaAsistidos.refresh()
    },
    async myProvider(ctx) {
      // eslint-disable-next-line no-param-reassign
      ctx.dia = this.diaAtencion
      // eslint-disable-next-line no-param-reassign
      ctx.comida = [this.comida]
      // eslint-disable-next-line no-param-reassign
      ctx.asistencia = 1

      const promise = store.dispatch('asistencia/fetchBecarioAsistencias', { queryParams: ctx })
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data

        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
  },

}
</script>
